import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
      // Arange TC users
      getArrangeJournalSelectOptions(ctx) {
        return new Promise((resolve, reject) => {
          axiosIns
            .get(`getArrangeJournalSelectOptions`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getLast7DaysJournals(ctx) {
        return new Promise((resolve, reject) => {
          axiosIns
            .get(`getLast7DaysJournals`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      searchJournalEntries(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`searchJournalEntries`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getLastSearchJournal(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getLastSearchJournal`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      searchJournalEntriesbyValueDate(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`searchJournalEntriesbyValueDate`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getSelectedAccountNames(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getSelectedAccountNames`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
      getselectedAccountCodes(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getselectedAccountCodes`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },


      saveSearch(ctx, searchData) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`saveSearch`, searchData)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },

      
      getLastSearch(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`getLastSearch`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
  },
};
